<template>
    <section class="section section--regular">
        <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
        <div class="wrap">
            <h2 class="section__heading">Restips i Sverige – First Camp rekommenderar</h2>
            <p class="section__content">
                Funderar du på vart du ska åka i sommar? Upptäck södra Sverige. Här är fem restips till
                toppdestinationer i södra Sverige.
            </p>
            <div class="section-images section-images--four">
                <figure class="section-image section-image--full-width">
                    <div
                        v-lazy-load-image="require('~/assets/images/restips/molle_omgivningar.jpg')"
                        class="section-image__image--rounded"
                    />
                </figure>
                <figure class="section-image section-image--full-width">
                    <div
                        v-lazy-load-image="require('~/assets/images/restips/stenso_utsikt.jpg')"
                        class="section-image__image--rounded"
                    />
                </figure>
                <figure class="section-image section-image--full-width">
                    <div
                        v-lazy-load-image="require('~/assets/images/restips/rostanga.jpg')"
                        class="section-image__image--rounded"
                    />
                </figure>
                <figure class="section-image section-image--full-width">
                    <div
                        v-lazy-load-image="require('~/assets/images/restips/ahus_strand.jpg')"
                        class="section-image__image--rounded"
                    />
                </figure>
            </div>
            <div class="section__buttons wrap">
                <LocalizedLink :to="{ name: 'restips' }" class="button button--primary">
                    Se restipsen här
                </LocalizedLink>
            </div>
        </div>
        <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->
    </section>
</template>

<script>
export default {
    name: 'FirstCampTravelTipsInSweden',
};
</script>
