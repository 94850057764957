<template>
    <section class="usps section section--floating">
        <div :class="['usps__container', { wrap: isLargeScreen }]">
            <div class="usps--list-wrap">
                <div :class="['usp-list', { wrap: !isLargeScreen }]">
                    <h2 class="usp-list__heading">
                        {{ $t('sections.renters_usps.security_heading') }}
                    </h2>
                    <div class="usp">
                        <img
                            :alt="$t('icon_img_alt.insurance')"
                            class="usp__icon"
                            src="~/assets/images/big-icons/insurance.svg"
                        />
                        <div class="usp_content">
                            <h3 class="usp__heading">{{ $t('sections.renters_usps.insurance_subheading') }}</h3>
                            <p class="usp__text">
                                {{ $t('sections.renters_usps.insurance_text') }}
                            </p>
                        </div>
                    </div>
                    <div class="usp">
                        <img
                            :alt="$t('icon_img_alt.road_assistance')"
                            class="usp__icon"
                            src="~/assets/images/big-icons/tow.svg"
                        />
                        <div class="usp_content">
                            <h3 class="usp__heading">{{ $t('sections.renters_usps.road_assistance_subheading') }}</h3>
                            <p class="usp__text">
                                {{ $t('sections.renters_usps.road_assistance_text') }}
                            </p>
                        </div>
                    </div>
                    <div class="usp">
                        <img
                            :alt="$t('icon_img_alt.secure_payments')"
                            class="usp__icon"
                            src="~/assets/images/big-icons/credit-card.svg"
                        />
                        <div class="usp_content">
                            <h3 class="usp__heading">{{ $t('sections.renters_usps.secure_payments_subheading') }}</h3>
                            <p class="usp__text">
                                {{ $t('sections.renters_usps.secure_payments_text') }}
                            </p>
                        </div>
                    </div>
                    <div class="usp">
                        <img
                            :alt="$t('icon_img_alt.phone_support')"
                            class="usp__icon"
                            src="~/assets/images/big-icons/seller.svg"
                        />
                        <div class="usp_content">
                            <h3 class="usp__heading">{{ $t('sections.renters_usps.support_subheading') }}</h3>
                            <p class="usp__text">
                                {{ $t('sections.renters_usps.support_text') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="usps--list-wrap">
                <div :class="['usp-list', { wrap: !isLargeScreen }]">
                    <h2 class="usp-list__heading">
                        {{ $t('sections.renters_usps.simplicity_heading') }}
                    </h2>
                    <div class="usp">
                        <img
                            :alt="$t('icon_img_alt.find_vehicle')"
                            class="usp__icon"
                            src="~/assets/images/big-icons/find.svg"
                        />
                        <div class="usp_content">
                            <h3 class="usp__heading">{{ $t('sections.renters_usps.find_vehicle_subheading') }}</h3>
                            <p class="usp__text">
                                {{
                                    $t('sections.renters_usps.find_vehicle_text', {
                                        siteCountry: $t(
                                            `localization.countries.${$store.getters.siteCountryCodeWithFallback}`
                                        ),
                                    })
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="usp">
                        <img
                            :alt="$t('icon_img_alt.low_price')"
                            class="usp__icon"
                            src="~/assets/images/big-icons/discount.svg"
                        />
                        <div class="usp_content">
                            <h3 class="usp__heading">{{ $t('sections.renters_usps.low_price_subheading') }}</h3>
                            <p class="usp__text">
                                {{ $t('sections.renters_usps.low_price_text', { siteName: $config.siteName }) }}
                            </p>
                        </div>
                    </div>
                    <div class="usp">
                        <img
                            :alt="$t('icon_img_alt.save_time')"
                            class="usp__icon"
                            src="~/assets/images/big-icons/time.svg"
                        />
                        <div class="usp_content">
                            <h3 class="usp__heading">{{ $t('sections.renters_usps.save_time_subheading') }}</h3>
                            <p class="usp__text">
                                {{ $t('sections.renters_usps.save_time_text') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="blue_bg" />
        <div class="cream_bg" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'RenterUsps',
    computed: {
        ...mapGetters(['isLargeScreen']),
    },
};
</script>
