<template>
    <div class="selected-vehicles__title">
        <svg-icon :name="title.iconName" />
        <div>
            <div class="selected-vehicles__title__count">{{ title.noOfVehicles }}</div>
            <LocalizedLink
                :to="{
                    path: `/${title.linkPath}`,
                }"
            >
                {{ title.linkText }}
            </LocalizedLink>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';
.selected-vehicles {
    &__title {
        display: flex;
        align-items: center;
        text-align: left;
        margin-bottom: 24px;
        @include font-size(16px);
        line-height: 24px;

        .svg-icon {
            width: 60px;
            height: 60px;
            margin-right: 24px;
        }

        &__count {
            font-weight: 600;
        }
    }
}
</style>
