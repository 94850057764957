<template>
    <div class="home-search-form">
        <LocationFilter
            :placeholder="$t('search_form.location_label')"
            :always-popup="true"
            @location-change="focusDatepicker"
        />

        <DateSelectInput class="home-search-form__input home-search-form__date" />

        <button class="button button--primary home-search-form__btn" type="button" @click.prevent="gotoVehicles">
            {{ $t('search_form.button') }}
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { buildNewQuery } from '~/helpers/queryParser';

import DateSelectInput from '~/components/form-elements/DateSelectInput';
import LocationFilter from '~/components/vehicles/LocationFilter';

export default {
    components: {
        LocationFilter,
        DateSelectInput,
    },
    computed: {
        ...mapGetters(['standByFilters']),
    },
    methods: {
        gotoVehicles() {
            const newRoute = {
                path: this.$i18n.t('common.motorhomes_page_path'),
                query: {
                    [this.$t('common.all_vehicles_query')]: 1,
                    ...buildNewQuery(this.standByFilters),
                },
            };

            this.$router.push(this.localePath(newRoute));
        },
        focusDatepicker(value) {
            if (value) {
                setTimeout(() => {
                    this.$root.$emit('focus-datepicker');
                }, 250);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

::v-deep .location-input {
    margin-bottom: 16px;

    &__predictions {
        @include sm {
            width: 450px;
        }

        @include lg {
            width: 550px;
        }
    }
}
</style>
