<template>
    <div class="rental-process section">
        <h2 class="rental-process__heading section__heading">
            {{ $t('sections.rental_process.heading') }}
        </h2>

        <ol class="rental-process__items wrap">
            <li class="process-item">
                <svg-icon name="find" class="process-item__icon" />
                <h3 class="process-item__heading">
                    {{ $t('sections.rental_process.step_1_heading') }}
                </h3>
                <p class="process-item__text">
                    {{ $t('sections.rental_process.step_1_text') }}
                </p>
            </li>
            <li class="process-item">
                <svg-icon name="interview" class="process-item__icon" />
                <h3 class="process-item__heading">
                    {{ $t('sections.rental_process.step_2_heading') }}
                </h3>
                <p class="process-item__text">
                    {{ $t('sections.rental_process.step_2_text') }}
                </p>
            </li>
            <li class="process-item">
                <svg-icon name="car-key" class="process-item__icon" />
                <h3 class="process-item__heading">
                    {{ $t('sections.rental_process.step_3_heading') }}
                </h3>
                <p class="process-item__text">
                    {{ $t('sections.rental_process.step_3_text') }}
                </p>
            </li>
        </ol>
        <div class="section__buttons wrap">
            <LocalizedLink
                :to="
                    localePath({
                        path: `/${$t('common.motorhomes_page_path')}`,
                        query: { [$t('common.all_vehicles_query')]: 1 },
                    })
                "
                class="button button--primary"
            >
                {{ $t('sections.rental_process.show_vehicles_button') }}
            </LocalizedLink>
            <a :href="$t('links.faq')" target="_blank" class="button button--secondary-contrast">
                {{ $t('sections.rental_process.read_more_button') }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RentalProcess',
};
</script>
