<template>
    <section class="do-you-own section">
        <div class="wrap">
            <h2 class="do-you-own__heading section__heading">
                {{ $t('sections.do_you_own.heading') }}
            </h2>
            <p class="do-you-own__text">
                {{ $t('sections.do_you_own.text') }}
            </p>
            <div class="section__buttons">
                <LocalizedLink
                    v-if="isLoggedIn && isOwner"
                    :to="{ name: 'owner-publish-vehicle' }"
                    class="button button--primary"
                >
                    {{ $t('sections.do_you_own.signup_button') }}
                </LocalizedLink>
                <ModalLink v-if="!isLoggedIn" to="sign-up" :data="{ role: 'owner' }" class="button button--primary">
                    {{ $t('sections.do_you_own.signup_button') }}
                </ModalLink>
                <LocalizedLink :to="{ name: 'rent-out' }" class="button button--secondary-contrast">
                    {{ $t('sections.do_you_own.read_more_button') }}
                </LocalizedLink>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalLink from '~/components/modals/ModalLink.vue';

export default {
    name: 'DoYouOwnAVehicle',
    components: {
        ModalLink,
    },
    computed: {
        ...mapGetters(['isLoggedIn', 'isOwner']),
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.do-you-own {
    padding: 50px 0;
    background: $cream-white;

    @include md {
        padding: 100px 0;
    }

    &__heading {
        margin-bottom: 20px;
    }

    &__text {
        max-width: 580px;
        margin: auto;
        text-align: left;
    }
}
</style>
