<template>
    <section class="home-hero section">
        <SkewedImage
            :src="require('~/assets/images/header-image.webp')"
            :src-small="require('~/assets/images/header-image-small.webp')"
            :preload-images="true"
        />
        <div class="home-hero__content-wrapper">
            <div class="home-hero__content">
                <h1 class="home-hero__heading">
                    {{ $t('sections.home_hero.heading') }}
                </h1>
                <p class="home-hero__subheading">
                    {{ $t('sections.home_hero.subheading', { count: platformVehicles.roundedCount }) }}
                </p>
                <HomeSearchForm class="home-hero__search-form" />
            </div>
            <div class="home-hero__bottom">
                <LocalizedLink :to="{ name: 'rent-out' }" class="rent-out-button button button--light">
                    {{ $t('sections.home_hero.rent_out_button') }}
                </LocalizedLink>
                <PlatformRatings class="home-hero__ratings" />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HomeSearchForm from '~/components/vehicles/HomeSearchForm.vue';
import PlatformRatings from '~/components/PlatformRatings.vue';
import SkewedImage from '~/components/SkewedImage.vue';

export default {
    name: 'HomeHero',
    components: {
        HomeSearchForm,
        PlatformRatings,
        SkewedImage,
    },
    computed: {
        ...mapGetters(['platformVehicles']),
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.home-hero {
    // This nesting is needed so we override .section styling
    &.section {
        position: relative;
        padding: $md-spacing 10px 0;

        @include md {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0;
            margin: 0 $lg-spacing;
        }
    }

    &__content-wrapper {
        max-width: 400px;

        .header-banner-active & {
            margin-top: 170px;
        }

        @include md {
            margin-top: 70px;
            margin-left: 10%;
        }
    }

    &__content {
        @extend %card;
        border-radius: 20px;
        padding: 30px;
        position: relative;

        @include md {
            padding: 40px;
            width: auto;
        }

        .insurance-badge {
            position: absolute;
            top: -115px;
            right: 0;
            height: 140px;
            width: 140px;

            @include sm {
                top: -70px;
                right: -70px;
            }
        }
    }

    &__heading {
        @extend %heading-1;
        text-transform: none;
        margin-bottom: 24px;
        z-index: 10;

        @include md {
            @include font-size(32px);
        }
    }

    &__subheading {
        @extend %heading-3;
        color: $secondary-text-color;
        text-transform: none;
        font-weight: 400;
    }

    &__search-form {
        width: 100%;
        margin: 20px auto 0;
        text-align: center;
        max-width: 360px;

        @include sm {
            margin: 24px auto 0;
        }

        .home-search-form__input {
            margin-bottom: 15px;
        }

        .home-search-form__btn {
            height: 52px;
            padding: 0 30px;
            min-width: 160px;
            width: 100%;
            margin-top: 0;
        }
    }

    &__bottom {
        position: relative;
        width: 100%;

        .rent-out-button {
            width: fit-content;
            margin-top: $lg-spacing;
        }

        @include md {
            .rent-out-button {
                margin-top: $md-spacing;
            }
        }
    }

    &__ratings {
        padding: 30px 0 $sm-spacing 0;

        @include md {
            padding: $sm-spacing 0 $lg-spacing 0;
        }
    }
}
</style>
