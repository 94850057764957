<template>
    <div class="home">
        <HomeHero />
        <MainUsps for="renters" />
        <Press />
        <client-only>
            <SelectedVehicles list-name="homepage" />
        </client-only>
        <RenterUsps />
        <RentalProcess />
        <InterviewBlurb type="renter" />
        <DoYouOwnAVehicle />
        <PopularPlaces />
        <FirstCampTravelTipsInSweden v-if="$store.getters.isCountrySweden && $i18n.locale === 'se'" />
        <Contact />
    </div>
</template>

<router>
{
    meta: {
        hideGettingStartedProgressBar: true
    }
}
</router>

<script>
import HomeHero from '~/components/sections/HomeHero.vue';
import RentalProcess from '~/components/sections/RentalProcess.vue';
import InterviewBlurb from '~/components/sections/InterviewBlurb.vue';
import SelectedVehicles from '~/components/sections/SelectedVehicles.vue';
import Contact from '~/components/sections/Contact.vue';
import Press from '~/components/sections/Press.vue';
import MainUsps from '~/components/sections/MainUsps.vue';
import RenterUsps from '~/components/sections/RenterUsps.vue';
import DoYouOwnAVehicle from '~/components/sections/DoYouOwnAVehicle.vue';
import FirstCampTravelTipsInSweden from '~/components/sections/FirstCampTravelTipsInSweden.vue';
import PopularPlaces from '~/components/sections/PopularPlaces';
import { actionTypes as cmsActions } from '~/store/cms';

export default {
    name: 'Home',
    components: {
        PopularPlaces,
        HomeHero,
        RentalProcess,
        InterviewBlurb,
        SelectedVehicles,
        Contact,
        Press,
        MainUsps,
        RenterUsps,
        DoYouOwnAVehicle,
        FirstCampTravelTipsInSweden,
    },
    async fetch({ store, app }) {
        try {
            if (['se', 'no', 'fi', 'dk', 'fi-sv', 'ch', 'fr'].includes(app.i18n.locale)) {
                await store.dispatch(cmsActions.FETCH_PAGE, `/${app.i18n.t('common.motorhomes_page_path')}`);
            }
        } catch (err) {
            app.$sentry.captureException(err);
        }
    },
    head() {
        return {
            title: `${this.$config.siteName} – ${this.$t('common.default_seo_page_title_suffix')}`,
            titleTemplate: '%s',
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.$t('pages.home.seo_page_description', {
                        siteName: this.$config.siteName,
                        siteCountry: this.$t(
                            `localization.countries.${this.$store.getters.siteCountryCodeWithFallback}`
                        ),
                    }),
                },
            ],
        };
    },
    nuxtI18n: {
        paths: {
            se: '/',
            'fi-sv': '/',
            en: '/',
            no: '/',
            fi: '/',
            de: '/',
            dk: '/',
            ch: '/',
            fr: '/',
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.home {
    background: $white;

    @include md {
        padding-top: 40px;
    }
}
</style>
